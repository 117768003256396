



import { Component, Vue } from 'vue-property-decorator';

@Component({
	name: 'IndexTranscription',
})
export default class IndexTranscription extends Vue {}
